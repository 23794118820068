.projects__container{
    grid-template-columns: repeat(3, 380px);
    justify-content: center;
    column-gap: 3rem;
}

.projects__content{
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: whitesmoke;
    width: 350px;
    /* padding: 6rem 0 2rem 2.5rem; */
}

.projects__icon{
    display: block;
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-1);
}

.projects__title{
    font-size: var(--h3-font-size);
    margin-bottom: var(--mb-1);
    font-weight: var(--font-medium);
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.project__des{
    text-align: justify;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
}

.projects
{
    background-color: white;
}
.projects__button{
    color: rgba(27, 182, 161, 1);
    font-size: 1.1rem;
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    cursor: pointer;
    font-weight: bolder;

}.button-div{
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
}
.projects__button-icon{
    font-size: 1.2rem;
    transition: 0.3s;
    
}

.projects__button:hover .projects__button-icon{
    transform: translateX(00.25rem);
}

.projects__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.projects__modal-content{
    width: 500px;
    position: relative;
    background-color: whitesmoke;
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}



.projects__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.projects__modal-title,
.projects__modal-description{
    text-align: center;
}

.projects__modal-title{
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.projects__modal-description{
    font-size: var(--small-font-size);
    padding: 0 1rem;
    margin-bottom: var(--mb-2);
    text-align: justify;
}

.projects__modal-projects{
    row-gap: 0.75rem;
    grid-template-columns: repeat(2, 200px);
    justify-content: center;
    column-gap: 0.5rem;
}

.projects__modal-project{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}

.projects__modal-icon{
    color: var(--title-color);
    font-size: 1.1rem;
}

.projects__modal-info{
    font-size: var(--small-font-size);
}

/* Active modal */
.active-modal{
    opacity: 1;
    visibility: visible;
}

/* BREAKPOINTS */
/* for large device */
@media screen and (max-width: 992px){
    .projects__container{
        grid-template-columns: max-content;
    }
   }
   /* for medium device */
   @media screen and (max-width: 768px){
    .projects__container{
        grid-template-columns: max-content;
    }
    .projects__content{
        padding: 3.5rem 0.5rem 1.25rem 1.5rem;
    }
    .projects__icon{
        font-size: 1.5rem;
    }
    .projects__container{
        grid-template-columns: max-content;
     }
   }
   
   @media screen and (max-width: 576px){
     .projects__modal-content{
        padding: 4.5rem 1.5rem 2.5rem;
     }
     .projects__modal-description{
        padding: 0;
     }
     .projects__container{
        grid-template-columns: max-content;
     }
   }
   /* for small device */
   @media screen and (max-width: 350px){
     .projects__container{
        grid-template-columns: max-content;
     }
     .projects__content{
        padding-right: 6rem;
     }
   }