.scrollUp {
  position: fixed;
  right: 2.5rem;
  bottom: 5%;
  background-color: rgb(43, 42, 42);
  opacity: 0.8;
  padding: 0.3rem 0.5rem;
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
}

.scrollup__icon {
  font-size: 1.5rem;
  color: var(--container-color);
}

.scrollUp:hover {
  background-color: black;
}

.show-scroll {
  display: none;
}

/* BREAKPOINTS */
/* for large device */
@media screen and (max-width: 992px) {
  .scrollUp {
    right: 1.5rem;
    padding: 0.25rem 0.4rem;
  }

  .scrollup__icon {
    font-size: 1.25rem;
  }
}
/* for medium device */
@media screen and (max-width: 768px) {
  .show-scroll {
    display: none;
  }

  .scrollUp {
    bottom: 10%;
  }
}

/* for small device */
@media screen and (max-width: 350px) {
    .scrollUp{
        right: 1rem;
    }
}
