.skills__container {
  grid-template-columns: repeat(2, 600px);
  column-gap: 3rem;
  justify-content: center;
}

.name-container {
  width: 20%;
  margin-top: 20px;
}
.inner-container {
  width: 80%;
  margin-left: 0px;
}
.skills__content {
  background-color: whitesmoke;
  border: 1px solid rgba(27, 182, 161, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
}

.skills__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  text-align: center;
  margin-bottom: var(--mb-1-5);
}

.Skills {
  background-color: white;
}

.skills__box {
  column-gap: 2.5rem;
}

.skills__group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}

.skills__data {
  display: flex;
  column-gap: 0.5rem;
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: var(--title-color);
}

.skills__name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  line-height: 18px;
}

.skills__level {
  font-size: var(--tiny-font-size);
}

.logo {
  padding: 0 350px 50px 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Wrap logos if the screen is small */
}

.logo{
  text-align: justify;
}

.skill__paragraph{
  margin-bottom: 50px;
}

.logo__image {
  cursor: pointer;
  margin: 2px;
  padding-top: 20px;
}

.logo__image:hover{
  animation: shake 2s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.skills {
  padding: 0px 0 0px;
}
.skill-content {
  padding-top: 50px;
}
.section-heading h2 {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 1px solid #e5ebf2;
  text-align: center;
}
.single-progress-txt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.single-progress-txt h3 {
  color: rgba(27, 182, 161, 1);
  font-size: 16px;
  margin-left: 15px;
}
.barWrapper {
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.progress {
  border-radius: 3px;
  background: #e6ecf3;
  height: 8px;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.progress-bar {
  background: rgba(27, 182, 161, 1);
  border-radius: 3px;
  height: 100%;
  transition: width 1.5s ease-in-out;
  
}

.tooltip{
   position:relative;
   float:right;
}
.tooltip > .tooltip-inner {
   background-color: transparent;
   padding: 1px 5px;
   color: rgba(27, 182, 161, 1);
   font-weight: 500;
   font-size: 12px;
   border-radius: 0;
}
.popOver + .tooltip > .tooltip-arrow {
   border-left: 6px solid transparent;
   border-right: 6px solid transparent;
   border-top: 6px solid #fff;
   bottom: -1px;
   transform: translateX(-50%);
   margin-left: 0;
}
/* BREAKPOINTS */
/* for large device */
@media screen and (max-width: 992px) {
  .skills__container {
    grid-template-columns: 1fr;
    row-gap: 2rem;
  }

  .logo {
    padding: 0 100px 20px 100px;
  }
}
/* for medium device */
@media screen and (max-width: 576px) {
  .skills__container {
    grid-template-columns: 1fr;
  }
  .skills__content {
    padding: 1.5rem;
  }

  .logo {
    padding: 0 20px 5px 20px;
  }
}
/* for small device */
@media screen and (max-width: 350px) {
  .skills__box {
    column-gap: 1.25rem;
  }
  .skills__name {
    font-size: var(--small-font-size);
  }
  .logo {
    padding: 0 5px 5px 5px;
  }
}
